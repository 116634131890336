* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.web-bg-color {
    background-color: #fcf6bd;
}

.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f6f9fc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.basket-card {
    margin: 10px auto;
    width: 98%;
    border-radius: 10px;
    border: 1px solid #bfbfbf;
    padding: 10px;
}